// eslint-disable-next-line
import i18n from './translate';
import { useState } from 'react';
import { useTranslation} from 'react-i18next';
import "animate.css/animate.min.css";
import about_bg from './comp/about-bg.jpg';
import logo from './comp/logo.png';
import about_c2 from './comp/about-c2.jpg';

function ShowSection(input){
  const [show, setShow] = useState(false);
  const isContain = (dom) =>{
      const totalHeight = window.innerHeight || document.documentElement.clientHeight;
      const totalWidth = window.innerWidth || document.documentElement.clientWidth;
      if(!dom) return false;
      const { top, right } = dom.getBoundingClientRect();
      return (right <= totalWidth && top + 300 <= totalHeight);
  }
  const isActive = () => {
    if (isContain(document.getElementById(input.id))) {
      setShow(true);
    }
  }
  window.addEventListener('scroll', isActive, true);
  if(show){
    return (
      <div id={input.id} className={input.className}>
        {input.content}
      </div>
    )
  }else{
    return (
      <div id={input.id} className={input.className +' about_page'}>
      </div>
    )
  }
}

function ShowHeader(input){
  const [show, setShow] = useState(false);
  const isContain = (dom) =>{
      const totalHeight = window.innerHeight || document.documentElement.clientHeight;
      const totalWidth = window.innerWidth || document.documentElement.clientWidth;
      if(!dom) return false;
      const { top, right } = dom.getBoundingClientRect();
      return (right <= totalWidth && top + 300 <= totalHeight);
  }
  const isActive = () => {
    if (isContain(document.getElementById(input.id))) {
      setShow(true);
    }
  }
  window.addEventListener('scroll', isActive, true);
  if(show){
      return <h2 id={input.id} className={input.className + " fadein"} >{input.name}</h2>
  }else{
      return <h2 id={input.id} className={input.className}> </h2>
  }
}

export default function About() {
  const { t } = useTranslation()
  return (

    <div className="App bg-transparent">
      <header className="App-header bg-transparent bg-center navbar-bg bg-cover" style={{backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(' + about_bg + ')'}}>
        <div className="video-content space-y-2">
          <h1 className="font-light text-6xl font-mono tracking-wide list-none uppercase align-top text-white fadein">{t('about.title')}</h1>
        </div>
      </header>
      <div className="mx-auto w-full flex-row flex-wrap bg-gray-200 dark:bg-stone-900 py-8">
        <div className=" justify-between items-center m-auto w-8/12 ">
          <div className=" text-black dark:text-slate-400 flex flex-wrap justify-between items-center my-8">
            <ShowHeader id="p1" name={t('about.h1')} className='font-bold text-4xl text-center leading-10 m-auto w-10/12 pt-16 mb-16'/>
            <div className="w-full lg:w-1/2 place-items-center ">
              <ShowSection id="p1" className="" content={<p id="about_c1" className="font-light text-center text-lg m-auto w-10/12 mb-8 to-right">{t('about.c1')}</p>} />
            </div>
            <ShowSection id="p1" className="w-full lg:w-1/2 scale-75" content={<img className="w-full to-left" src={logo} alt="logo" />} />      
          </div>
          <div className=" text-black dark:text-slate-400 flex flex-wrap justify-between items-center mt-8 ">
            <ShowHeader id="p2" name={t('about.h2')} className='font-bold text-4xl text-center leading-10 m-auto w-10/12 pt-16'/>
            <ShowSection id="p2" className="w-full lg:w-1/2 scale-75" content={<img className="w-full rounded-3xl border-solid border-neutral-400 border-2 to-right" src={about_c2} alt="me" />} />
            <div className="w-full lg:w-1/2 place-items-center ">
              <ShowSection id="p2" className="" content={<p id="about_c1" className="font-light text-center text-lg m-auto w-10/12 mb-8 to-left">{t('about.c2')}</p>} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}